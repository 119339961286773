<template>
  <div
    style="background: #f6f8fc; padding: 70px 0px"
    :style="$vuetify.breakpoint.smAndDown ? 'padding: 40px 0px' : ''"
  >
    <v-main>
      <v-container>
        <v-row
          no-gutters
          align="start"
          :style="$vuetify.breakpoint.smAndDown ? '' : 'margin: 70px 0px'"
        >
          <v-col cols="6" v-if="!$vuetify.breakpoint.smAndDown">
            <img
              :src="aboutUs?.images?.[0]?.path"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '480px'"
              height="600px"
              style="object-fit: cover; border-radius: 40px"
              alt="about us img"
            />
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="6" sm="12">
            <v-row no-gutters align="center">
              <div
                style="
                  width: 12px;
                  height: 12px;
                  background: #144fa9;
                  border-radius: 50%;
                "
              />
              <h2 class="aboutUsTitle">{{ "about_us_section" | localize }}</h2>
            </v-row>
            <p class="aboutUsText" style="margin-top: 12px; margin-bottom: 0px">
              {{ aboutUs?.translations?.content }}
            </p>
            <v-row no-gutters align="start">
              <v-col
                class="px-0 py-0"
                style="text-align: left; margin-top: 40px; padding-right: 20px"
                v-for="item in facts"
                :key="item.id"
                cols="4"
              >
                <p style="margin: 0px" class="aboutUsTitle">
                  {{ item?.translations?.title }}+
                </p>
                <span class="aboutUsText">
                  {{ item?.translations?.content }}
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            v-if="$vuetify.breakpoint.smAndDown"
            style="margin-top: 20px"
          >
            <img
              :src="aboutUs?.images?.[0]?.path"
              :width="$vuetify.breakpoint.smAndDown ? '100%' : '480px'"
              height="600px"
              style="object-fit: cover; border-radius: 40px"
              alt="about us img"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </div>
</template>

<script>
//import settingsService from '../../../../requests/Admin/settingsService';
import contentService from "../../../../requests/Content/contentService";
export default {
  data: () => ({
    facts: [],
    aboutUs: {},
  }),
  mounted() {
    this.getContent();
  },
  methods: {
    async getContent() {
      await contentService.getContent(1).then((res) => {
        this.aboutUs = res.data.find((content) => content.section == "Main");
        this.facts = res.data
          .filter((content) => content.section == "Facts")
          .reverse();
      });
    },
  },
};
</script>

<style scoped>
.aboutUsTitle {
  color: var(--Black, #1b1b1b);
  font-family: "MacPaw Fixel";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-left: 8px;
}
.aboutUsText {
  color: #4b5262;
  font-family: "MacPaw Fixel";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
}
</style>