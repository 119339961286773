import requestService from "../requestService";
import store from '@/store';

export default {
	async createContent(form) {
		const response = await requestService.post(`/cp_dash/content`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateContent(uuid, form) {
		const response = await requestService.post(`/cp_dash/content/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async changeContentStatus(uuid, form) {
		const response = await requestService.post(`/cp_dash/content/status/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getContent(page) {
		const response = await requestService.get(`/v1/content?page=${page}`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBlog(category, page) {
		const response = await requestService.get(`/v1/blog${category !== null && category !== undefined && category !== 0 ? `/category/${category}` : ''}?page=${page}`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getRandomBlog() {
		const response = await requestService.get(`/v1/blog/carousel/6`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getContentForAdmin() {
		const response = await requestService.get(`/cp_dash/content`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getContentForAdminBySection(page, search) {
		console.log(search)
		const response = await requestService.get(`/cp_dash/content?page=${page}&search=${search}&order=created_at&direction=DESC`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getBlogBySlug(slug) {
		const response = await requestService.get(`/v1/blog/${slug}`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBlogById(id) {
		const response = await requestService.get(`/cp_dash/content/${id}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getPopularBlog() {
		const response = await requestService.get(`/v1/blog/popular`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getImportantBlog() {
		const response = await requestService.get(`/v1/blog/important`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async getBlogStatistic() {
		const response = await requestService.get(`/cp_dash/statistic/blogs`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}`, 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async createCategory(form) {
		const response = await requestService.post(`/cp_dash/category`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async updateCategory(uuid, form) {
		const response = await requestService.post(`/cp_dash/category/${uuid}`, form, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
	async getCategories(page) {
		const response = await requestService.get(`/cp_dash/category?page=${page}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` },
		})
		return response?.data
	},
	async getVisibleCategory() {
		const response = await requestService.get(`/v1/category`, {
			headers: { 'Accept-Language': store.getters.locale.toLowerCase() }
		})
		return response?.data
	},
	async deleteContent(uuid) {
		const response = await requestService.delete(`/cp_dash/content/${uuid}`, {
			headers: { Authorization: `Bearer ${store.getters.loggedUserPTrans.token}` }
		})
		return response?.data
	},
}